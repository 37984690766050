<template>
    <div class="suppliers-table">
        <vx-card>
            <vue-bootstrap4-table
                    :rows="data"
                    :columns="columns"
                    :config="config"
                    :actions="actions"
                    @on-change-query="onChangeQuery"
                    :total-rows="total_rows"
                    column-selection>
                <template slot="row_actions" slot-scope="props">
                    <div class="column-actions-group">
                        <vx-tooltip text="Bearbeiten" position="left">
                            <router-link  :to="'/suppliers/'+props.row.id">
                            <vs-button color="warning" type="filled" icon="edit" size="small"
                                      ></vs-button>
                            </router-link>
                        </vx-tooltip>
                        <vx-tooltip text="Deaktivieren" position="left" v-if="props.row.active">
                            <vs-button color="danger"
                                       type="filled"
                                       icon="close"
                                       size="small"
                                       @click="toggleClientActive(props.row.id, props.row.active)"></vs-button>
                        </vx-tooltip>
                        <vx-tooltip text="Aktivieren" position="left" v-if="!props.row.active">
                            <vs-button color="success"
                                       type="filled"
                                       icon="check"
                                       size="small"
                                       @click="toggleClientActive(props.row.id, props.row.active)"></vs-button>
                        </vx-tooltip>
                        <vx-tooltip text="Löschen" position="left">
                            <vs-button color="primary"
                                       type="filled"
                                       icon="delete_forever"
                                       size="small"
                                       @click="openDeleteConfirm(props.row.id)"></vs-button>
                        </vx-tooltip>
                    </div>
                </template>
                <template slot="empty-results">
                    Keine Lieferanten gefunden.
                </template>
            </vue-bootstrap4-table>
        </vx-card>
    </div>
</template>

<script>
    import VueBootstrap4Table from '@/plugins/vue-bootstrap4-table-master/src/components/VueBootstrap4Table'

    import ApiService from "../../../api";

    import moment from 'moment';
    import QueryHelper from "../../../mixins/helper/query.helper";
    import * as qs from "qs";

    export default {
        components: {
            VueBootstrap4Table
        },
        data() {
            return {
                data: [],
                actions: [
                    {
                        btn_text: "Hinzufügen",
                        router_link: '/suppliers/add',
                        color: 'success'
                    }
                ],
                columns: [
                    {
                        label: "Kunden-Nr.",
                        name: "customer_number",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        column_classes: 'customer-number-column',
                        showCol: true,
                    },
                    {
                        label: "Firma",
                        name: "company_name",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    }, {
                        label: "Stadt",
                        name: "city",
                        filter: {
                            type: "simple",
                            placeholder: "Suchen..."
                        },
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Aktiv",
                        filter: {
                            type: "simple",
                            placeholder: "1 oder 0"
                        },
                        name: "active",
                        sort: true,
                        row_text_alignment: 'text-left',
                        column_text_alignment: 'text-left',
                        showCol: true,
                    },
                    {
                        label: "Actions",
                        slot_name: "row_actions",
                        sort: false,
                        row_text_alignment: 'text-right',
                        column_text_alignment: 'text-right',
                        column_classes: 'actions-column',
                        showCol: true,
                    }],
                config: {
                    checkbox_rows: false,
                    show_refresh_button: false,
                    show_reset_button: false,
                    highlight_row_hover_color: '#f8f8f8',
                    server_mode: true
                },
                queryParams: {
                    sort: [],
                    filters: [],
                    global_search: "",
                    per_page: 10,
                    page: 1,
                },
                total_rows: 0,
                selectedForDialog: null
            }
        },
        computed: {
            requestParams() {
                let params = this.queryParams;

                params.filters.push({
                  expression: 'group',
                  filter: [
                    {
                      name: 'type',
                      text: 'supplier',
                      expression: 'exact',
                      operator: 'or',
                    },
                    {
                      name: 'type',
                      text: 'freelancer',
                      expression: 'exact',
                      operator: 'or',
                    },
                    {
                      name: 'type',
                      text: 'partner',
                      expression: 'exact',
                      operator: 'or',
                    }
                    ]
                })


                return QueryHelper.parseRequestParams(params);
            }
        },
        methods: {
            fetchData() {
                this.$vs.loading()

                ApiService.get('clients', {
                    params: this.requestParams,
                    paramsSerializer: params => qs.stringify(params, {arrayFormat: 'indices'})
                }).then(response => {
                    this.data = response.data.result;
                    this.total_rows = response.data.total;
                    this.$vs.loading.close()
                })
            },
            toggleClientActive(id, current) {

                const payload = {
                    active: !parseInt(current)
                };

                ApiService.put('clients/' + id, payload).then(response => {
                    this.fetchData();

                    if(response.data.status === 'success') {
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der Status wurde erfolgreich geändert',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        })
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Status konnte nicht geändert werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })

                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Status konnte nicht geändert werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
            },
            openDeleteConfirm(id) {
                this.selectedForDialog = id;

                this.$vs.dialog({
                    type: 'confirm',
                    color: 'primary',
                    title: 'Bestätigen',
                    text: 'Sind Sie sich sicher dass dieser Lieferant gelöscht werden soll?',
                    accept: this.acceptDelete,
                    acceptText: 'Löschen',
                    cancelText: 'Abbrechen'
                })
            },
            acceptDelete() {
                ApiService.delete('clients/' + this.selectedForDialog).then((error) => {

                    this.fetchData();

                    if(response.data.status === 'success') {
                        return this.$vs.notify({
                            title: 'Erfolgreich',
                            text: 'Der Lieferant wurde erfolgreich gelöscht',
                            iconPack: 'feather',
                            icon: 'icon-alert-circle',
                            color: 'success'
                        });
                    }

                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Lieferant konnte nicht gelöscht werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                }).catch((error) => {
                    this.$vs.notify({
                        title: 'Fehlgeschlagen',
                        text: 'Der Lieferant konnte nicht gelöscht werden.',
                        iconPack: 'feather',
                        icon: 'icon-alert-circle',
                        color: 'danger'
                    })
                })
            },
            formatDate(value) {
                return moment(new Date(value)).format('DD.MM.YYYY')
            },
            onChangeQuery(queryParams) {
                this.queryParams = queryParams;
                this.fetchData();
            },
        },
        mounted() {
            //this.fetchData()
        }
    }

</script>

<style lang="scss">
    .suppliers-table {
        .vbt-table-wrapper {
            .actions-column {
                width: 110px;
            }
            .customer-number-column {
                width: 190px;
            }
        }
    }
</style>
